<template>
	<div>
		<div v-if="!addOrEditReady">
			<LoadingSpinner class="col-12" />
		</div>
		<div v-else>
			<div class="mt-lg-4">
				<h3>3. {{ $t('pax.résultats') }}</h3>
			</div>

			<template v-if="criteres_local.length > 2 && Object.keys(recap).length > 0 && stallions_local.length > 0">
				<div class="paragraphes">{{ $t('pax.completer_caracterisation_jument') }} {{ criteres_remaining }} {{ $t('pax.criteres') }}.</div>
				<div v-if="!pax_express && show_suggestion" class="text-center mb-3"> 
					<a href="#" class="btn btn-pax" @click.prevent="goToSuggestion">{{ $t('pax.voir_suggestion_pax') }} <font-awesome-icon :icon="['far', 'angle-right']" /></a>
				</div>
				<div class="stallionGrade" v-for="stallion in stallions_local" :key="stallion.horse_id" :class="colorGrade(stallion.horse_id)">
					<div class="row no-gutters align-items-center" @click="showCompatibilite">
						<div class="col-5">
							<div class="stallionName">{{ stallion.horse_nom }}</div>
						</div>
						<div class="col-7 text-center">
							<span class="bullet" v-for="critere in criteres_local" :class="getColorBullets(stallion.horse_id, critere.criteres_id)[0]" :key="critere.criteres_id"><font-awesome-icon :icon="['fas', 'circle']"/></span>
						</div>
					</div>
				</div>
				<div class="text-right">
					<button class="btn btn-primary btn-next" @click="showCompatibilite">
						<template v-if="pax_express">{{ $t('pax.comparer_etalons') }}</template>
						<template v-else>{{ $t('pax.comparer_jusqua_3_etalons') }}</template> <font-awesome-icon :icon="['fal', 'long-arrow-right']" />
					</button>
				</div>
			</template>
			<template v-else>
				<div v-if="stallions_local.length == 0" class="closePopupCompatibilite">
					<template v-if="pax_express">
						{{ $t('pax.selectionner_stallion_compatibilite') }}
					</template>
					<template v-else>
						{{ $t('pax.selectionner_jusqua_stallion_compatibilite') }}
					</template>
				</div>
				<div v-else class="closePopupCompatibilite">{{ $t('pax.completer_criteres_voir_compatibilite') }}</div>
				<div class="blurry">
					{{ $t('pax.completer_caracterisation_jument') }} {{ criteres_remaining }} {{ $t('pax.criteres') }}.
					<div class="stallionGrade mt-3"><div class="row no-gutters align-items-center"><div class="col-5"><div class="stallionName">AIR JORDAN Z*GFE</div></div><div class="col-7 text-center"><span class="bullet orange"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet green"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet red"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span></div></div></div>
					<div class="stallionGrade"><div class="row no-gutters align-items-center"><div class="col-5"><div class="stallionName">AIR JORDAN Z*GFE</div></div><div class="col-7 text-center"><span class="bullet orange"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet green"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet red"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span></div></div></div>
					<div class="text-right"><button class="btn btn-primary btn-next"><template v-if="pax_express">{{ $t('pax.comparer_etalons') }}</template><template v-else>{{ $t('pax.comparer_jusqua_3_etalons') }}</template> <font-awesome-icon :icon="['fal', 'long-arrow-right']" /></button></div>
				</div>
				<div v-if="stallions_local.length == 0 && (!isLg && !isXl)" class="text-left"><a href="#" class="btn btn-dark btn-back" @click.prevent="goToStallionSelection">{{ $t('pax.selectionner_stallion') }} <span class="icone"><font-awesome-icon :icon="['far', 'undo-alt']" /></span></a></div>
			</template>
		</div>

		<b-modal dialog-class="modal-pax" ref="modal" hide-footer id="paxModalSelection">
            <template v-slot:modal-title>
                {{ $t("pax.comparer_jusqua_3_etalons") }}
            </template>

        	<div v-for="(stallion, index) in stallions_selected" class="form-group" :key="'stallion_'+index">
				<label for="season">{{ $t('pax.stallion') }} {{ index+1 }}</label>
				<e-select
					v-model="stallions_selected[index]"
					track-by="horse_id"
					label="horse_nom"
					:placeholder="$t('pax.selectionner_un_stallion')"
					:options="stallions_selection_modal"
					:allow-empty="false"
					:show-labels="false"
					:sortable="false"
					:searchable="false"
				>
					<template slot="singleLabel" slot-scope="{ option }">
						<div class="stallionGrade" :key="option.horse_id" :class="colorGrade(option.horse_id)">
							<div class="row no-gutters align-items-center" >
								<div class="col-5">
									<div class="stallionName">{{ option.horse_nom }}</div>
								</div>
								<div class="col-7 text-center">
									<span class="bullet" v-for="critere in criteres_local" :class="getColorBullets(option.horse_id, critere.criteres_id)[0]" :key="critere.criteres_id"><font-awesome-icon :icon="['fas', 'circle']"/></span>
								</div>
							</div>
						</div>
		            </template>
		            <template slot="option" slot-scope="{ option }">
						<div class="stallionGrade" :key="option.horse_id" :class="colorGrade(option.horse_id)">
							<div class="row no-gutters align-items-center" >
								<div class="col-5">
									<div class="stallionName">{{ option.horse_nom }}</div>
								</div>
								<div class="col-7 text-center">
									<span class="bullet" v-for="critere in criteres_local" :class="getColorBullets(option.horse_id, critere.criteres_id)[0]" :key="critere.criteres_id"><font-awesome-icon :icon="['fas', 'circle']"/></span>
								</div>
							</div>
						</div>
		            </template>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
			</div>

			<div class="text-center">
				<button class="btn btn-pax" @click="sendStallionsSelected">{{ $t('pax.comparer') }}</button>
			</div>
        </b-modal>

        <Suggestion ref="suggestion" :mare="mare" :stallions="stallions" :stallions_available="stallions_available" :criteres="criteres" :suggestion.sync="suggestion"/>
	</div>
</template>

<script type="text/javascript">
    import { EventBus } from 'EventBus'
	import Navigation from "@/mixins/Navigation.js"
	import PaxMixin from "@/mixins/Pax.js"
	import Tools from "@/mixins/Tools.js";
	import Window from '@/mixins/Window.js'
	import _cloneDeep from 'lodash/cloneDeep';


	export default {
		name: "ResultCompatibiliteHFull",
		props: {
			criteres: {
				type: Array,
				default: () => ( [] )
			},
			stallions: {
				type: Array,
				default: () => ( [] )
			},
			stallions_available: {
				type: Array,
				default: () => ( [] )
			},
			displayCompatibilite: {
				type: Boolean,
				default: () => (false)
			},
			mare: {
				type: Object,
				default: () => ( null )
			},
			pax_express: {
				type: Boolean,
				default: () => false
			},
		},
		mixins: [Navigation, PaxMixin, Tools, Window],
		data () {
			return {
				addOrEditReady: false,
				stallions_local: [],
				criteres_local: [],
				recap: {},
				stallions_selected: [null, null, null],
				suggestion: [],
				show_note: true,
				stallions_selection_modal: [],
				show_suggestion: true
			}
		},
		created() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.addOrEditReady = false

				this.stallions_local = _cloneDeep(this.stallions)

				this.addOrEditReady = true

				if(!this.pax_express) {
					setTimeout(() => this.show_note = false, 2000);
				}

				this.stallions_selection_modal = this.stallions_local

				this.criteres_local = _cloneDeep(this.criteres)
				this.calculCompatibilite()
			},

			async calculCompatibilite() {
				this.recap = await this.paxCalculCompatiblite(this.stallions_local, this.criteres_local)
			},

			async showCompatibilite() {
				if(this.stallions_local.length > 3) {
					this.$refs.modal.show()
					return false
				}
				this.$emit('update:step_label', "comparaison_V")
				this.$emit('update:stallions_comparaison', this.stallions_local)
				this.$emit('update:displayCompatibilite', true)
			},

			getColorBullets(stallion_id, criteres_id = null) {
				if(this.recap[stallion_id] === undefined) {
					return false
				}


				let criteres_local = _cloneDeep(this.criteres_local)

				if(criteres_id != null) {
					criteres_local = criteres_local.filter(elem => {
						return elem.criteres_id == criteres_id
					})
				}

				let tab_bullets = []
				
				for (let index = 0; index < criteres_local.length; index++) {
					const element = criteres_local[index];
					
					if(parseInt(this.recap[stallion_id][element.criteres_id]) < element.criteres_red) {
						tab_bullets.push('red')
					}
					else if(parseInt(this.recap[stallion_id][element.criteres_id]) >= element.criteres_green) {
						tab_bullets.push('green')
					}
					else if(parseInt(this.recap[stallion_id][element.criteres_id]) >= element.criteres_red && parseInt(this.recap[stallion_id][element.criteres_id]) < element.criteres_green) {
						tab_bullets.push('orange')
					}
				}

				return tab_bullets
			},

			colorGrade(stallion_id) {
				// Pour les ronds : on fait un premier essai avec vert si compatibilité supérieure à 50%, orange si 30 à 50% et rouge si inférieure à 30%
				// Pour les fonds de cartouche étalons
				// Vert foncé si tous les ronds sont verts
				// Vert clair si que des ronds verts et 1 où 2 oranges et 0 rouges
				// Jaune si 3 oranges ou plus et 0 rouges ou 1 rouge et 1 ou 2 oranges
				// Orange si 1 rouge et 3 oranges ou plus ou 2 rouges et 1 ou 2 oranges
				// Rouge si 2 rouges et 3 oranges ou plus ou plus de 2 rouges

				const tab_bullets = this.getColorBullets(stallion_id)
				if(!tab_bullets) {
					return 'noir'
				}
				const nb_elems = tab_bullets.length
				const nb_elems_green = tab_bullets.filter((elem) => elem == 'green').length
				const nb_elems_red = tab_bullets.filter((elem) => elem == 'red').length
				const nb_elems_orange = tab_bullets.filter((elem) => elem == 'orange').length


				if(nb_elems == nb_elems_green) {
					return 'dark_green'
				}
				else if(nb_elems_green >= 1 && nb_elems_orange <= 2 && nb_elems_red == 0) {
					return 'green'
				}
				else if((nb_elems_red == 2 && nb_elems_orange >= 3) || nb_elems_red > 2) {
					return 'red'
				}
				else if((nb_elems_red == 1 && nb_elems_orange >= 3) || (nb_elems_red == 2 && nb_elems_orange <= 2)) {
					return 'orange'
				}
				else if((nb_elems_orange >= 3 && nb_elems_red == 0) || (nb_elems_red == 1 && nb_elems_orange <= 2)) {
					return 'yellow'
				}

				return 'noir'
			},

			goToStallionSelection() {
				this.$emit('update:step_label', "stallions")
			},

			sendStallionsSelected() {
				this.$refs.modal.hide()
				this.$emit('update:step_label', "comparaison_V")
				this.$emit('update:stallions_comparaison', this.stallions_selected)
				this.$emit('update:displayCompatibilite', true)
			},

			async goToSuggestion() {
				this.$refs.suggestion.openModal()
			},

			async savePanier() {
				if(this.mare && this.mare.horse_id && this.stallions_local.length > 0) {
					const stallions_ids = this.stallions_local.map(stallion => stallion.horse_id)
					await this.saveMarePanier(this.mare.horse_id, stallions_ids)
				}
			}
			
		},
		computed: {
			criteres_remaining() {
				let slider = this.criteres_local.filter(critere => critere.slider)
				return this.criteres_local.length - slider.length
			}
		},
		watch: {
			stallions(val) {
				this.stallions_local = _cloneDeep(val)
				this.stallions_selection_modal = _cloneDeep(val)
				this.calculCompatibilite()
				this.savePanier()
			},
			'criteres': {
				deep: true,
				handler(val) {
					this.show_suggestion = true
					this.criteres_local = _cloneDeep(val)
					this.calculCompatibilite()
				}
			},
			'suggestion': {
				deep: true,
				handler(val) {
					this.show_suggestion = false
					this.$emit('update:suggestion', val)
				}
			},
			'stallions_selected': {
				deep: true,
				handler(val) {
					let ids = []
					val.forEach(stallion => {
						if(stallion && stallion.horse_id) {
							ids.push(stallion.horse_id)
						}
					})
					this.stallions_selection_modal = this.stallions_local.filter(stallion => !ids.includes(stallion.horse_id))
				}
			}
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			Suggestion : () => import('@/components/Pax/Suggestion')
		}
	}
</script>
